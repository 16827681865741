import {formActions} from '@/enums/formActions'

const initState = () => ({})

export const state = initState

export const getters = {}

export const mutations = {}

export const actions = {
  async saveLimitation({commit}, {limitations}) {
    await this.$catch(
      async () => {
        await this.$axios.$post(`/assetslimitation`, limitations)
        this.$toast.success(
          this.$i18n.t('saved_successfully')
        )
      },
      formActions.Save,
      limitations.assetID
    )
  },
}
