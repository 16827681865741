const initState = () => ({
  priceQuotes: [],
  page: 1,
  totalQuotes: 0,
  options: {},
})

export const state = initState

export const mutations = {
  setPriceQuotes(state, {priceQuotes}) {
    state.priceQuotes = priceQuotes
  },
  setTotalQuotes(state, {totalQuotes}) {
    state.totalQuotes = totalQuotes
  },
  setOptions(state, {options}) {
    state.options = options
  },
  reset(state) {
    Object.assign(state, initState())
  },
  incressePirceQouteNotCount(state,id){

    let objIndex = state.priceQuotes.findIndex(obj => obj.ID === id)
    console.log(state.priceQuotes[objIndex].noteCount)
    state.priceQuotes[objIndex].noteCount=state.priceQuotes[objIndex].noteCount+1
    console.log(state.priceQuotes[objIndex].noteCount)
  }
}

export const actions = {
  async fetchPriceQuotes({commit, state}, {filters}) {
    const {page, itemsPerPage} = state.options
    const result = await this.$axios.$get(
      '/PriceQuotes/Closed?pageSize=' +
        (itemsPerPage ?? 10) +
        '&pageNo=' +
        (page ?? 1) +
        filters
    )
    commit('setPriceQuotes', {priceQuotes: result.items})
    commit('setTotalQuotes', {totalQuotes: result.pagingData.itemCount})

    return result
  },
}
