const initState = () => ({
  userDetails: null,
  availableModules: [],
  usersCustomers: [],
  selectedUserCustomer: undefined,
})

export const state = initState

export const getters = {
  getAvailableModules: (state) => () => {
    return state.availableModules
  },
  getUserDetails: (state) => () => {
    return state.userDetails
  },
}

export const mutations = {
  setUserDetails(state, {userDetails}) {
    state.userDetails = userDetails
  },
  setAvailableModules(state, {availableModules}) {
    state.availableModules = availableModules
  },
  setUsersCustomers(state, {usersCustomers}) {
    state.usersCustomers = usersCustomers
  },
  setSelectedCustomer(state, {selectedUserCustomer}) {
    state.selectedUserCustomer = selectedUserCustomer
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async getUserDetails({commit}) {
    const userDetails = await this.$axios.$get('/User/MyProfile')
    commit('setUserDetails', {userDetails})
  },
  async fetchAvailableModules({commit}) {
    const availableModules = await this.$axios.$get('/User/AvailableModules')
    commit('setAvailableModules', {
      availableModules: availableModules.map((element) => {
        return {text: element.name, value: element.id}
      }),
    })
  },
  async fetchUsersCustomers({commit}) {
    const usersCustomers = await this.$axios.$get(`/User/user-customers`)
    commit('setUsersCustomers', {usersCustomers})
  },
  async saveGeneralInfo({commit}, {generalInfo}) {
    return await this.$axios.$post('/User/GeneralInfo', generalInfo)
  },
  async saveContactInfo({commit}, {contactInfo}) {
    return await this.$axios.$post('/User/ContactInfo', contactInfo)
  },
  async saveBillingInfo({commit}, {billingInfo}) {
    return await this.$axios.$post('/User/BillingInfo', billingInfo)
  },
  async saveCreditCardData({commit}, {creditCardInfo}) {
    return await this.$axios.$post('/User/CreditCardInfo', creditCardInfo)
  },
  async saveShippingInfo({commit}, {shippingInfo}) {
    return await this.$axios.$post('/User/ShippingInfo', shippingInfo)
  },
  async changePassword({commit}, {passwordInfo}) {
    return await this.$axios.$post('/User/ChangePassword', passwordInfo)
  },
  async saveSettingInfo({commit}, {settingInfo}) {
    const formData = new FormData()
    if (settingInfo.img) {
      formData.append(`image`, settingInfo.img, settingInfo.img.name)
    }

    formData.append(`imageChanged`, settingInfo.imageChanged)
    formData.append(`startupModule`, settingInfo.startupModuleID)

    return await this.$axios.$post('/User/SettingInfo', formData)
  },
}
